import "tailwindcss/tailwind.css";
import React from "react";
import { SessionProvider } from "next-auth/react";
import Script from "next/script";
import { DefaultSeo } from "next-seo";
import SEO from "../next-seo.config";
import { useEffect } from "react";
import { useRouter } from "next/router";
import PageScaffolding from "components/pageScaffolding";
import * as FullStory from "@fullstory/browser";
import * as gtag from "utils/gtag";
import { GA_TRACKING_ID } from "utils/gtag";
import { AppProps } from "next/app";

// https://dev.to/sharmakushal/hide-all-console-logs-in-production-with-just-3-lines-of-code-pp4
if (process.env.IS_PRODUCTION) {
  console.log = () => {};
}

if (typeof window !== "undefined") {
  FullStory.init({
    orgId: process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID || "",
    devMode: Boolean(process.env.IS_DEVELOPMENT),
  });
}

const App: React.FC<AppProps & { err: any }> = ({
  Component,
  pageProps,
  err,
}: AppProps & { err: any }) => {
  const router = useRouter();

  //Google Analytics
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (process.env.IS_PRODUCTION) {
        gtag.pageview(url);
      }
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <DefaultSeo {...SEO} />
      <SessionProvider session={pageProps.session}>
        <PageScaffolding>
          <Component {...pageProps} err={err} />
        </PageScaffolding>
      </SessionProvider>

      {/* Global Site Tag (gtag.js) - Google Analytics
      https://github.com/vercel/next.js/blob/canary/examples/with-google-analytics/pages/_app.js
      */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
    </>
  );
};
export default App;
