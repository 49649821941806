// TODO -- Question for Jason: I don't see a meaningful distinction between your next-seo.config.js and this file, yet I am getting a "warning"
// that is blocking commits in light of the new husky rule.  Disabling for now.
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    titleTemplate: 'Symbola | %s',
    defaultTitle:
      'Symbola | Legal Tools for Startups',
    canonical: 'https://symbo.la',
    description:
    'Legal tools for startups. Incorporate for free.',
    openGraph: {
      type: 'website',
      locale: 'en_US',
      url: 'https://symbo.la',
      site_name: 'Symbola',
      description:
        'Legal tools for startups. Incorporate for free.',
      title: 'Symbola',
      images: [
        {
          url: 'https://symbo.la/open-graph.png',
          width: 900,
          height: 800,
          type: 'image/png',
          alt: 'Symbola',
        },
      ],
    },
    twitter: {
      handle: '@GetSymbola',
      site: '@GetSymbola',
      cardType: 'summary_large_image'
    },
  };