//based on vested-new, added a more basic version to facilitate fullstory

import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import * as FullStory from "@fullstory/browser";

interface PageScaffoldingProps {
  children: JSX.Element;
}

const PageScaffolding: React.FC<PageScaffoldingProps> = ({
  children,
}: PageScaffoldingProps) => {
  const router = useRouter();
  const { data: session, status } = useSession();

  useEffect(() => {
    const handleRouteChangeComplete = () => {
      if (
        status === "authenticated" &&
        !process.env.IS_DEVELOPMENT &&
        session?.symbolaUser?.["_id"]
      ) {
        FullStory.identify(session.symbolaUser["_id"], {
          email: session.symbolaUser.email,
        });
      }
    };

    // initial call on first page load
    handleRouteChangeComplete();

    router.events.on("routeChangeComplete", handleRouteChangeComplete);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [status, session, router.events]);

  return (
    <div>
      <main>{children}</main>
    </div>
  );
};
export default PageScaffolding;
